import { all, takeLatest } from "redux-saga/effects";
import * as SignupActionType from "./signup/actionTypes";
import * as ApplicationPolicyActionType from "./applicationpolicy/actionTypes";
import * as MappingActionType from "./mapping/actionTypes";
import * as DomainBlockingActionType from "./domain-blocking/actionTypes";
import * as BreakActionType from "./break/actionTypes";
import * as ShiftActionType from "./shift/actionTypes";
import * as Wellness360ActionType from "./wellness-summary/actionTypes";
import * as DesignationActionType from "./designation/actionType";
import * as AttendanceSummaryActionType from "./attendance-summary/actionTypes";
import * as AttendanceDetailedActionType from "./attendance-detailed/actionTypes";
import * as RoleActionType from "./role/actionType";
import * as UserActionType from "./users/actionTypes";
import * as TeamActionType from "./team/actionType";
import * as TenantActionType from "./organization/actionTypes";
import * as ActivitySummaryActionType from "./activity-summary/actionTypes";
import * as ProductivitySummaryActionType from "./productivity-summary/actionTypes";
import * as ProductivityDetailsActionType from "./productivity-detail/actionTypes";
import * as ActivityDetailActionType from "./activity-detail/actionTypes";
import * as ApplicationSummaryActionType from "./application-summary/actionTypes";
import * as ApplicationDetailedActionType from "./application-detail/actionTypes";
import * as WorkplaceSettingActionType from "./workplace-settings/actionTypes";
import * as Wellness360detailsActionType from "./wellness-details/actionTypes";
import * as ReportsDailyAttendanceActionType from "./reports/dailyAttendance/actionType";
import * as BreakReportActionType from "./reports/break-report/actionTypes";
import * as ProductivityReportActionType from "./reports/productivity-report/actionTypes";
import * as ActivityReportActionType from "./reports/activity-report/actionTypes";
import * as ReportMonthlyInoutActionType from "./reports/monthlinout/actionType";
import * as ReportMonthlyAttendanceActionType from "./reports/monthly-attendance/actionType";
import * as TimelineActionType from "./timeline/actionTypes";
import * as LivestreamActionType from "./livestream/actionTypes";
import * as GoalsActionType from "./goals/actionTypes";

import * as ApplicationReportActionType from "./reports/application-report/actionTypes";
import * as GroupinsightReportActionType from "./reports/groupinsight-report/actionTypes";
import * as DynamicReportActionType from "./reports/dynamic-report/actionTypes";
import * as AlertsActionType from "./alerts/actionTypes";
import * as RolesActionType from "./roles/actionTypes";
import * as ProjectsActionType from "./projects/actionTypes";
import * as NotesActionType from "./notes/actionTypes";
import * as TaskActivitiesActionType from "./task-activities/actionTypes";
import * as TasksActionType from "./tasks/actionTypes";
import * as BillingActionType from "./billing/actionTypes";
import * as ScreenshotActionType from "./screenshot/actionTypes";
import * as TenantSettingsActionType from "./tenant-settings/actionTypes";
import * as DownloadActionType from "./download/actionTypes";
import * as FieldActionType from "./field/actionTypes";
import * as LeaveTypeActionType from "./leave-type/actionTypes";
import * as LeaveAction from "./leave/actionTypes";
import * as UserDeviceActionType from "./user-device/actionTypes";
import * as ProjectsReportActionType from "./reports/project-report/actionTypes";
import * as LogsReportActionType from "./reports/logs-report/actionTypes";
import * as manualTimeActionType from "./manualtime/actionTypes";
import * as UserScorecardActionType from "./user-scorecard/actionTypes";
import * as GenAIActionType from "./gen-ai/actionTypes";
import * as IntegrationsActionType from "./integrations/actionTypes";

import * as CrossDayAttendanceReportActionType from "./reports/cross-day-attendance-report/actionTypes";
import * as TimesheetActionType from "./timesheet/actionTypes";

import {
  Signup,
  VerifyToken,
  SignupSetPassword,
  SetDomain,
  CheckDomain,
  SetResendEmail,
  GetTokenFromCode,
  VerifyGoogleToken,
  reconfirmCompleteSetupSaga,
  acceptInvitationSaga,
} from "./signup/saga";
import {
  GetApplicationpolicyList,
  CreateApplicationpolicy,
  EditApplicationpolicy,
  DeleteAplicationPolicy,
} from "./applicationpolicy/saga";
import {
  GetApplicationCategoryList,
  GetApplicationPolicyMappingList,
  EditApplicationUrl,
  CreateApplicationPolicyMapping,
  EditApplicationPolicyMapping,
  GetApplicationUrlList,
  searchApplicationUrlList,
  GetApplicationUrlDownloadList,
} from "./mapping/saga";
import {
  GetDomainblockingPolicyList,
  CreateDomainblockingPolicy,
  EditDomainblockingPolicy,
} from "./domain-blocking/saga";
import { GetBreaksData, CreateBreaksData, EditBreaksData } from "./break/saga";
import { GetShiftsData, CreateShiftsData, EditShiftsData } from "./shift/saga";
import {
  getWorkplaceSetting,
  editWorkplaceSetting,
} from "./workplace-settings/saga";

import {
  Wellness360HealthyEmployees,
  Wellness360SummaryOverburdenedEmployees,
  Wellness360HealthyEmployeesPreviousMonth,
  Wellness360SummaryOverallScore,
  Wellness360SummaryTopTeams,
  Wellness360SummaryTeamWiseUtilization,
  Wellness360MeOverallScore,
} from "./wellness-summary/saga";
import {
  TeamList,
  CreateTeam,
  UpdateTeam,
  DeleteTeam,
  UpdateMemberTeam,
  UpdateManagerTeam,
  UpdateUserSetting,
} from "./team/saga";
import {
  CreateDesignation,
  DesignationList,
  UpdateDesignation,
} from "./designation/saga";
import {
  GetAttendanceSummaryWedigt,
  GetAttendanceSummaryToday,
  GetAttendanceSummaryWorkingTime,
  GetAttendanceSummaryWorkingTimeLess,
  GetAttendanceSummaryBreakTrendLess,
  GetAttendanceSummaryAttendanceTrend,
  GetAttendanceSummaryBreakTrend,
  GetAttendanceSummaryLateArrivalTendency,
  GetAttendanceSummaryAttendanceTrendLess,
  GetAttendanceSummaryLateArrivalTendencyLess,
  GetAttendanceSummaryBreakTrendDateWise,
  GetAttendanceSummaryAttendanceTrendCurrent,
} from "./attendance-summary/saga";
import {
  GetAttendanceDetailedAttendanceTrend,
  GetAttendanceDetailedEmployeeList,
  GetAttendanceDetailedEmployeePunchLogList,
  GetAttendanceDetailedAttendanceTrendGraph,
  GetAttendanceDatewiseAttendancelist,
  GetDownloadAttendanceEmployeeList,
} from "./attendance-detailed/saga";
import {
  CreateRole,
  UpdateRole,
  RoleList,
  GenerateStealthKeySaga,
  roleAssignmentsSearch,
} from "./role/saga";
import {
  CreateUser,
  UpdateUser,
  UserList,
  UpdateInfoUser,
  CreateInviteUser,
  RoleUserList,
  UpdateRoleUser,
  MeDetail,
  TeamWiseUserList,
  uploadAvatar,
  GetUserIdsList,
  FieldUserList,
  FieldUserById,
  FieldUserLastLocation,
  UserBulkImportValidate,
  UserBulkImport,
  UserBulkImportGetJobStatus,
  UserBulkImportlogs,
  userNewList,
  userSearchList,
  deactivateUsersSaga,
  activateUsersSaga,
  exportUserList,
  MeDetailRecheckSaga,
  fetchMeInfoSaga,
  bulkTeamUpdateSaga,
} from "./users/saga";

import {
  GetTenantData,
  EditTenantData,
  CreateTenantData,
} from "./organization/saga";
import {
  GetActivitySummaryActivityLevelBreakDown,
  GetActivitySummaryOnlineBreaDownData,
  GetActivitySummaryTopButtomActiveTeam,
  GetActivitySummaryTopAppUrlData,
  GetActivitySummaryTeamWiseActivityBreakDown,
} from "./activity-summary/saga";
import {
  GetApplicationSummaryTopAppUrlData,
  GetApplicationSummaryTotalApplicationUsageData,
  GetApplicationSummaryTotalUrlUsageData,
  GetApplicationSummaryCategoryUtilizationData,
} from "./application-summary/saga";

import {
  GetapplicationDetailCategoryList,
  GetapplicationDetailTeamWiseAppUtilization,
  GetapplicationDetailTeamWiseUrlUtilization,
  GetapplicationDetailApplicationWiseAppUrlUtilization,
  GetapplicationDetailSearchWiseUrlDetail,
  GetapplicationDetailSearchWiseAppDetail,
  GetapplicationDetailSearchWiseUserUrlDetail,
  GetapplicationDetailSearchWiseUserAppDetail,
  GetapplicationDetailSearchWiseGraphUrlDetail,
  GetapplicationDetailSearchWiseGraphAppDetail,
  GetapplicationDetailSearchWiseTitleAppDetail,
  GetapplicationDetailSearchWiseTitleUrlDetail,
  GetApplicationDetailedTotalApplicationUsageData,
  GetApplicationDetailedTotalUrlUsageData,
  GetapplicationDetailSearchWiseAppUrlDetail,
} from "./application-detail/saga";

import {
  ReportMonthlyInOut,
  DownloadReportMonthlyInOut,
} from "./reports/monthlinout/saga";

import {
  ReportMonthlyAttendance,
  ReportDownloadMonthlyAttendance,
} from "./reports/monthly-attendance/saga";

import {
  GetActivityDetailedEmployeeList,
  GetactivityDetailWorkingTimeTrend,
  GetActivityEmployeeDetailedEmployeeList,
} from "./activity-detail/saga";

import {
  GetProductivitySummaryCategory,
  ProductivitySummaryWorking,
  ProductivitySummaryAppUrl,
  ProductivitySummaryBreakDown,
  GetProductivitySummaryTopBottom,
  GetProductivitySummaryTeamWise,
} from "./productivity-summary/saga";
import {
  GetProductivityDetailWorkingTimeTrend,
  GetProductivityDetailTeamWiseProductivityTrend,
  GetProductivityDetailedEmployeeList,
  GetProductivityEmployeeDetailedEmployeeList,
} from "./productivity-detail/saga";
import {
  fetchHourlyActivityProductivitySaga,
  fetchPowerLogsSaga,
  fetchTimelineTableSaga,
  fetchSessionAppLogsSaga,
  fetchPunchLogsSaga,
} from "./timeline/saga";

import {
  GetBreakReportData,
  GetBreakDownloadReportData,
  GetMonthlyBreakReportData,
  GetMonthlyBreakDownloadReportData,
} from "./reports/break-report/saga";
import {
  GetProductivityReportData,
  GetProductivityDownloadReportData,
} from "./reports/productivity-report/saga";
import {
  GetActivityReportData,
  GetActivityDownloadReportData,
} from "./reports/activity-report/saga";
import {
  getApplicationReportExportListData,
  GetApplicationReportGraphData,
  GetApplicationReportListData,
} from "./reports/application-report/saga";
import { downloadDynamicReportSaga } from "./reports/dynamic-report/saga";
import { GetGroupInsightReportData } from "./reports/groupinsight-report/saga";

import {
  GetReportsDailyAttendance,
  GetReportsDownloadDailyAttendance,
} from "./reports/dailyAttendance/saga";

import {
  GetWellnessDetailsTrendsData,
  GetWellnessDetailsEmlpoyeeListData,
  GetWellnessEmployeeDetailsEmlpoyeeListData,
  GetWellness360DownloadEmployeeData,
} from "./wellness-details/saga";
import {
  fetchUserDataSaga,
  startLivestreamSaga,
  startScreenshotSaga,
} from "./livestream/saga";
import {
  createAlertSaga,
  deleteAlertSaga,
  fetchAlertLogsSaga,
  downloadAlertLogsSaga,
  fetchAlertsSaga,
  updateAlertSaga,
} from "./alerts/saga";
import {
  createProjectSaga,
  deleteProjectSaga,
  fetchProjectsSaga,
  getProjectSaga,
  updateProjectSaga,
} from "./projects/saga";
import {
  createTaskActivitySaga,
  deleteTaskActivitySaga,
  fetchTaskActivitiesSaga,
  updateTaskActivitySaga,
} from "./task-activities/saga";
import {
  createTaskAttachmentSaga,
  createTaskSaga,
  deleteTaskAttachmentSaga,
  deleteTaskSaga,
  fetchChildTasksSaga,
  fetchTaskAttachmentsSaga,
  fetchTasksSaga,
  getTaskAttachmentSaga,
  getTaskSaga,
  updateTaskAttachmentSaga,
  updateTaskSaga,
} from "./tasks/saga";
import {
  fetchInvoicesSaga,
  fetchPlanGroupSaga,
  fetchSubscriptionsSaga,
  initiateSubscriptionSaga,
  sendInvoiceViaEmailSaga,
  updateSubscriptionSaga,
} from "./billing/saga";

import {
  downloadScreenshotSaga,
  downloadScreenshotsZipSaga,
  fetchSecreenshotListByUserSaga,
  flagScreenshotSaga,
  getFlaggedScreenshotSaga,
  unflagScreenshotSaga,
} from "./screenshot/saga";
import {
  fetchTenantSettingsSaga,
  updateTenantSettingsSaga,
  fetchUserSession,
} from "./tenant-settings/saga";
import {
  downloadKeyConfigSaga,
  downloadStandardAppSaga,
  downloadStealthAppSaga,
  fetchAppVersionDetailsSaga,
} from "./download/saga";
import {
  createNoteSaga,
  deleteNoteSaga,
  fetchNotesSaga,
  getNoteSaga,
  updateNoteSaga,
} from "./notes/saga";
import { fetchDailyPingsSaga } from "./field/saga";
import {
  GetLeaveType,
  CreateLeaveType,
  EditLeaveType,
} from "./leave-type/saga";

import { GetLeave, CreateLeave, EditLeave } from "./leave/saga";
import {
  getPcUserDeviceInfo,
  getMobileUserDeviceInfo,
  getUserDeviceCount,
  getMobilePlatform,
  getPCPlatform,
  getPCSystemType,
  getPCMyzenVersion,
  getMobileUserDeviceInfoDownload,
  getPcUserDeviceInfoDownload,
} from "./user-device/saga";
import {
  getComparativeAnalysisUserPerformanceInfo,
  getComparativeAttendanceUserPerformanceInfo,
  getComparativeGoalUserPerformanceInfo,
  getComparativeApplicationUserPerformanceInfo,
} from "./user-scorecard/saga";
import {
  fetchGoalAchievementDistributionSaga,
  fetchTeamwiseGoalComparisonSaga,
  fetchUserwiseGoalAnalyticsSaga,
  fetchUserwiseGoalAnalyticsDownloadSaga,
} from "./goals/saga";

import {
  fetchProjectsReportSaga,
  downloadProjectsReportSaga,
} from "./reports/project-report/saga";

import {
  fetchManualTimeSaga,
  createManualTimeSaga,
  updateManualTimeSaga,
  deleteManualTimeSaga,
} from "./manualtime/saga";
import {
  createRoleSaga,
  deleteRoleSaga,
  fetchRolesSaga,
  updateRoleSaga,
} from "./roles/saga";
import {
  fetchLogsReportSaga,
  downloadLogsReportSaga,
} from "./reports/logs-report/saga";
import { getResponseForGenAIQuerySaga } from "./gen-ai/saga";
import {
  fetchCrossDayAttendanceReportSaga,
  downloadCrossDayAttendanceReportSaga,
} from "./reports/cross-day-attendance-report/saga";
import {
  fetchTimesheetSaga,
  createTimesheetSaga,
  updateTimesheetSaga,
  deleteTimesheetSaga,
  bulkSubmitTimesheetSaga,
  editTimesheetDetailSaga,
} from "./timesheet/saga";
import {
  createIntegrationSaga,
  deleteIntegrationSaga,
  fetchIntegrationsSaga,
  updateIntegrationSaga,
} from "./integrations/saga";
export default function* Saga() {
  return yield all([
    takeLatest(SignupActionType.SIGNUP_CREATE_PROCESS, Signup),
    takeLatest(SignupActionType.SIGNUP_GET_TOKEN_PROCESS, GetTokenFromCode),
    takeLatest(SignupActionType.SIGNUP_VERIFY_TOKEN_PROCESS, VerifyToken),
    takeLatest(
      SignupActionType.ACCEPT_INVITATION_PROCESS,
      acceptInvitationSaga
    ),
    takeLatest(
      SignupActionType.RECONFIRM_COMPLETE_SETUP,
      reconfirmCompleteSetupSaga
    ),
    takeLatest(
      SignupActionType.SIGNUP_GOOGLE_VERIFY_TOKEN_PROCESS,
      VerifyGoogleToken
    ),

    takeLatest(SignupActionType.SIGNUP_SET_PASSWORD_PROCESS, SignupSetPassword),
    takeLatest(SignupActionType.SET_DOMAIN_PROCESS, SetDomain),
    takeLatest(SignupActionType.CHECK_DOMAIN_PROCESS, CheckDomain),
    takeLatest(SignupActionType.SET_RESEND_EMAIL_PROCESS, SetResendEmail),
    takeLatest(TeamActionType.TEAM_LIST_PROCESS, TeamList),
    takeLatest(
      ApplicationPolicyActionType.APPLICATIONPOLICY_LIST_PROCESS,
      GetApplicationpolicyList
    ),
    takeLatest(
      ApplicationPolicyActionType.APPLICATIONPOLICY_CREATE_PROCESS,
      CreateApplicationpolicy
    ),
    takeLatest(
      ApplicationPolicyActionType.APPLICATIONPOLICY_EDIT_PROCESS,
      EditApplicationpolicy
    ),
    takeLatest(
      ApplicationPolicyActionType.APPLICATIONPOLICY_DELETE_PROCESS,
      DeleteAplicationPolicy
    ),
    takeLatest(
      MappingActionType.APPLICATION_CATEGORY_LIST_PROCESS,
      GetApplicationCategoryList
    ),
    takeLatest(
      MappingActionType.APPLICATION_POLICY_MAPPING_LIST_PROCESS,
      GetApplicationPolicyMappingList
    ),
    takeLatest(
      MappingActionType.APPLICATION_URL_EDIT_PROCESS,
      EditApplicationUrl
    ),
    takeLatest(
      MappingActionType.APPLICATION_URL_LIST_PROCESS,
      GetApplicationUrlList
    ),

    takeLatest(
      MappingActionType.APPLICATION_POLICY_MAPPING_CREATE_PROCESS,
      CreateApplicationPolicyMapping
    ),
    takeLatest(
      MappingActionType.APPLICATION_POLICY_MAPPING_EDIT_PROCESS,
      EditApplicationPolicyMapping
    ),
    takeLatest(
      DomainBlockingActionType.DOMAIN_BLOCKING_POLICY_LIST_PROCESS,
      GetDomainblockingPolicyList
    ),
    takeLatest(
      DomainBlockingActionType.DOMAIN_BLOCKING_POLICY_CREATE_PROCESS,
      CreateDomainblockingPolicy
    ),
    takeLatest(
      DomainBlockingActionType.DOMAIN_BLOCKING_POLICY_EDIT_PROCESS,
      EditDomainblockingPolicy
    ),
    takeLatest(BreakActionType.BREAKS_LIST_PROCESS, GetBreaksData),
    takeLatest(BreakActionType.BREAKS_CREATE_PROCESS, CreateBreaksData),
    takeLatest(BreakActionType.BREAKS_EDIT_PROCESS, EditBreaksData),
    takeLatest(ShiftActionType.SHIFTS_LIST_PROCESS, GetShiftsData),
    takeLatest(ShiftActionType.SHIFTS_CREATE_PROCESS, CreateShiftsData),
    takeLatest(ShiftActionType.SHIFTS_EDIT_PROCESS, EditShiftsData),
    takeLatest(
      WorkplaceSettingActionType.WORKPLACE_SETTING_PROCESS,
      getWorkplaceSetting
    ),

    takeLatest(
      WorkplaceSettingActionType.WORKPLACE_SETTING_EDIT_PROCESS,
      editWorkplaceSetting
    ),

    takeLatest(
      DesignationActionType.DESIGNATION_CREATE_PROCESS,
      CreateDesignation
    ),

    takeLatest(DesignationActionType.DESIGNATION_LIST_PROCESS, DesignationList),
    takeLatest(
      DesignationActionType.DESIGNATION_UPDATE_PROCESS,
      UpdateDesignation
    ),

    takeLatest(RoleActionType.GENERATE_STEALTH_KEY, GenerateStealthKeySaga),

    takeLatest(RoleActionType.ROLE_CREATE_PROCESS, CreateRole),

    takeLatest(RoleActionType.ROLE_LIST_PROCESS, RoleList),
    takeLatest(RoleActionType.ROLE_UPDATE_PROCESS, UpdateRole),

    takeLatest(
      AttendanceSummaryActionType.ATTENDANCE_SUMMARY_WEDIGT_PROCESS,
      GetAttendanceSummaryWedigt
    ),
    takeLatest(
      AttendanceSummaryActionType.ATTENDANCE_SUMMARY_TODAY_PROCESS,
      GetAttendanceSummaryToday
    ),
    takeLatest(
      AttendanceSummaryActionType.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_PROCESS,
      GetAttendanceSummaryAttendanceTrend
    ),
    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_CATEGORY_LIST_PROCESS,
      GetapplicationDetailCategoryList
    ),
    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_PROCESS,
      GetapplicationDetailTeamWiseAppUtilization
    ),
    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_PROCESS,
      GetapplicationDetailTeamWiseUrlUtilization
    ),
    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_PROCESS,
      GetapplicationDetailApplicationWiseAppUrlUtilization
    ),
    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_PROCESS,
      GetapplicationDetailSearchWiseAppDetail
    ),

    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_PROCESS,
      GetapplicationDetailSearchWiseUrlDetail
    ),
    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_PROCESS,
      GetapplicationDetailSearchWiseUserAppDetail
    ),

    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_PROCESS,
      GetapplicationDetailSearchWiseUserUrlDetail
    ),
    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_PROCESS,
      GetapplicationDetailSearchWiseGraphAppDetail
    ),

    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_PROCESS,
      GetapplicationDetailSearchWiseGraphUrlDetail
    ),
    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_PROCESS,
      GetapplicationDetailSearchWiseTitleAppDetail
    ),
    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_PROCESS,
      GetapplicationDetailSearchWiseTitleUrlDetail
    ),

    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_TOTAL_URL_USAGE_PROCESS,
      GetApplicationDetailedTotalUrlUsageData
    ),

    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_PROCESS,
      GetapplicationDetailSearchWiseAppUrlDetail
    ),

    takeLatest(
      ApplicationDetailedActionType.APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_PROCESS,
      GetApplicationDetailedTotalApplicationUsageData
    ),

    takeLatest(
      AttendanceSummaryActionType.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_PROCESS,
      GetAttendanceSummaryAttendanceTrendLess
    ),
    takeLatest(
      AttendanceSummaryActionType.ATTENDANCE_SUMMARY_BREAK_TREND_PROCESS,
      GetAttendanceSummaryBreakTrend
    ),
    takeLatest(
      AttendanceSummaryActionType.ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_PROCESS,
      GetAttendanceSummaryBreakTrendDateWise
    ),

    takeLatest(
      AttendanceSummaryActionType.ATTENDANCE_SUMMARY_BREAK_TREND_LESS_PROCESS,
      GetAttendanceSummaryBreakTrendLess
    ),

    takeLatest(
      AttendanceSummaryActionType.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_PROCESS,
      GetAttendanceSummaryLateArrivalTendency
    ),

    takeLatest(
      AttendanceSummaryActionType.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_PROCESS,
      GetAttendanceSummaryLateArrivalTendencyLess
    ),

    takeLatest(
      AttendanceSummaryActionType.ATTENDANCE_SUMMARY_WORKING_TIME_PROCESS,
      GetAttendanceSummaryWorkingTime
    ),
    takeLatest(
      AttendanceSummaryActionType.ATTENDANCE_SUMMARY_WORKING_TIME_LESS_PROCESS,
      GetAttendanceSummaryWorkingTimeLess
    ),

    takeLatest(
      AttendanceDetailedActionType.ATTENDANCE_DETAILED_ATTENDANCE_TREND_PROCESS,
      GetAttendanceDetailedAttendanceTrend
    ),
    takeLatest(
      AttendanceSummaryActionType.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_PROCESS,
      GetAttendanceSummaryAttendanceTrendCurrent
    ),

    takeLatest(
      AttendanceDetailedActionType.ATTENDANCE_DETAILED_EMPLOYEE_LIST_PROCESS,
      GetAttendanceDetailedEmployeeList
    ),

    takeLatest(
      AttendanceDetailedActionType.ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_PROCESS,
      GetAttendanceDetailedEmployeePunchLogList
    ),

    takeLatest(
      AttendanceDetailedActionType.ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_PROCESS,
      GetAttendanceDetailedAttendanceTrendGraph
    ),

    takeLatest(UserActionType.FIELD_USER_LIST_PROCESS, FieldUserList),
    takeLatest(UserActionType.FIELD_USER_BY_ID_PROCESS, FieldUserById),
    takeLatest(
      UserActionType.FIELD_USER_LAST_LOCATION_PROCESS,
      FieldUserLastLocation
    ),

    takeLatest(UserActionType.USER_UPDATE_PROCESS, UpdateUser),
    takeLatest(UserActionType.ME_DETAILS_PROCESS, MeDetail),
    takeLatest(UserActionType.ME_DETAILS_RECHECK_PROCESS, MeDetailRecheckSaga),
    takeLatest(UserActionType.ROLE_USER_LIST_PROCESS, RoleUserList),
    takeLatest(UserActionType.ROLE_USER_UPDATE_PROCESS, UpdateRoleUser),
    takeLatest(UserActionType.TEAM_WISE_USER_LIST_PROCESS, TeamWiseUserList),
    takeLatest(UserActionType.SET_UPLOAD_AVATAR_PROCESS, uploadAvatar),

    takeLatest(TeamActionType.TEAM_CREATE_PROCESS, CreateTeam),
    takeLatest(TeamActionType.TEAM_UPDATE_PROCESS, UpdateTeam),
    takeLatest(TeamActionType.TEAM_DELETE_PROCESS, DeleteTeam),
    takeLatest(UserActionType.USER_LIST_PROCESS, UserList),
    takeLatest(UserActionType.USER_UPDATE_PROCESS, UpdateUser),
    takeLatest(UserActionType.USER_CREATE_PROCESS, CreateUser),
    takeLatest(UserActionType.USER_INFO_UPDATE_PROCESS, UpdateInfoUser),
    takeLatest(TenantActionType.TENANT_CREATE_PROCESS, CreateTenantData),
    takeLatest(TenantActionType.TENANT_EDIT_PROCESS, EditTenantData),
    takeLatest(TenantActionType.TENANT_LIST_PROCESS, GetTenantData),
    takeLatest(UserActionType.USER_INVITE_CREATE_PROCESS, CreateInviteUser),
    takeLatest(UserActionType.DEACTIVATE_USERS, deactivateUsersSaga),
    takeLatest(UserActionType.ACTVATE_USERS, activateUsersSaga),

    takeLatest(TeamActionType.TEAM_MEMBER_UPDATE_PROCESS, UpdateMemberTeam),
    takeLatest(TeamActionType.TEAM_MANEGER_UPDATE_PROCESS, UpdateManagerTeam),
    takeLatest(TeamActionType.UPDATE_USER_SETTING_PROCESS, UpdateUserSetting),
    takeLatest(
      ActivitySummaryActionType.ACTIVITY_SUMMARY_TOP_APPLICATION_URL_PROCESS,
      GetActivitySummaryTopAppUrlData
    ),
    takeLatest(
      ApplicationSummaryActionType.APPLICATION_SUMMARY_TOP_APPLICATION_URL_PROCESS,
      GetApplicationSummaryTopAppUrlData
    ),
    takeLatest(
      ApplicationSummaryActionType.APPLICATION_SUMMARY_CATEGORY_UTILIZATION_PROCESS,
      GetApplicationSummaryCategoryUtilizationData
    ),

    takeLatest(
      ApplicationSummaryActionType.APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_PROCESS,
      GetApplicationSummaryTotalApplicationUsageData
    ),
    takeLatest(
      ApplicationSummaryActionType.APPLICATION_SUMMARY_TOTAL_URL_USAGE_PROCESS,
      GetApplicationSummaryTotalUrlUsageData
    ),

    // takeLatest(
    //   ProductivitySummaryActionType.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_PROCESS,
    //   GetProductivitySummaryTeamWiseProductivityCategory
    // ),
    takeLatest(
      ActivitySummaryActionType.ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_PROCESS,
      GetActivitySummaryOnlineBreaDownData
    ),
    takeLatest(
      ActivitySummaryActionType.ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_PROCESS,
      GetActivitySummaryActivityLevelBreakDown
    ),

    takeLatest(
      ActivitySummaryActionType.ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_PROCESS,
      GetActivitySummaryTopButtomActiveTeam
    ),
    takeLatest(
      ActivitySummaryActionType.ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_PROCESS,
      GetActivitySummaryTeamWiseActivityBreakDown
    ),

    takeLatest(
      ActivityDetailActionType.ACTIVITY_DETAILED_WORKING_TIME_TREND_PROCESS,
      GetactivityDetailWorkingTimeTrend
    ),

    takeLatest(
      ActivityDetailActionType.ACTIVITY_DETAILED_EMPLOYEE_LIST_PROCESS,
      GetActivityDetailedEmployeeList
    ),

    takeLatest(
      ActivityDetailActionType.ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_PROCESS,
      GetActivityEmployeeDetailedEmployeeList
    ),

    takeLatest(
      Wellness360ActionType.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PROCESS,
      Wellness360HealthyEmployees
    ),

    takeLatest(
      Wellness360ActionType.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_PROCESS,
      Wellness360HealthyEmployeesPreviousMonth
    ),

    takeLatest(
      Wellness360ActionType.WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_PROCESS,
      Wellness360SummaryOverburdenedEmployees
    ),

    takeLatest(
      Wellness360ActionType.WELLNESS360_SUMMARY_OVERALL_SCORE_PROCESS,
      Wellness360SummaryOverallScore
    ),

    takeLatest(
      Wellness360ActionType.WELLNESS360_ME_OVERALL_SCORE_PROCESS,
      Wellness360MeOverallScore
    ),

    takeLatest(
      Wellness360ActionType.WELLNESS360_SUMMARY_TOP_TEAMS_PROCESS,
      Wellness360SummaryTopTeams
    ),

    takeLatest(
      Wellness360ActionType.WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_PROCESS,
      Wellness360SummaryTeamWiseUtilization
    ),

    takeLatest(
      ProductivitySummaryActionType.PRODUCTIVITY_SUMMARY_WORKING_TIME_PROCESS,
      ProductivitySummaryWorking
    ),
    takeLatest(
      ProductivitySummaryActionType.PRODUCTIVITY_SUMMARY_TOP_APP_URL_PROCESS,
      ProductivitySummaryAppUrl
    ),
    takeLatest(
      ProductivitySummaryActionType.PRODUCTIVITY_SUMMARY_TOP_CATEGORY_PROCESS,
      GetProductivitySummaryCategory
    ),
    takeLatest(
      ProductivityDetailsActionType.PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_PROCESS,
      GetProductivityDetailWorkingTimeTrend
    ),
    takeLatest(
      ProductivityDetailsActionType.PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_PROCESS,
      GetProductivityDetailTeamWiseProductivityTrend
    ),
    takeLatest(
      ProductivityDetailsActionType.PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_PROCESS,
      GetProductivityDetailedEmployeeList
    ),

    takeLatest(
      ProductivityDetailsActionType.PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_PROCESS,
      GetProductivityEmployeeDetailedEmployeeList
    ),

    takeLatest(
      ProductivitySummaryActionType.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_PROCESS,
      ProductivitySummaryBreakDown
    ),
    takeLatest(
      ProductivitySummaryActionType.PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_PROCESS,
      GetProductivitySummaryTopBottom
    ),
    takeLatest(
      ProductivitySummaryActionType.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_PROCESS,
      GetProductivitySummaryTeamWise
    ),
    takeLatest(
      GenAIActionType.GET_RESPONSE_FOR_GEN_AI_QUERY,
      getResponseForGenAIQuerySaga
    ),

    takeLatest(
      Wellness360detailsActionType.WELLNESS360_DETAILS_TRENDS_PROCESS,
      GetWellnessDetailsTrendsData
    ),

    takeLatest(
      Wellness360detailsActionType.WELLNESS360_DETAILS_EMPLOYEE_LIST_PROCESS,
      GetWellnessDetailsEmlpoyeeListData
    ),

    takeLatest(
      Wellness360detailsActionType.WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_PROCESS,
      GetWellnessEmployeeDetailsEmlpoyeeListData
    ),

    takeLatest(
      ReportsDailyAttendanceActionType.REPORTS_DAILY_ATTENDANCE_PROCESS,
      GetReportsDailyAttendance
    ),
    takeLatest(
      ReportsDailyAttendanceActionType.REPORTS_DOWNLOAD_DAILY_ATTENDANCE_PROCESS,
      GetReportsDownloadDailyAttendance
    ),

    takeLatest(BreakReportActionType.BREAKS_REPORT_PROCESS, GetBreakReportData),
    takeLatest(
      BreakReportActionType.BREAKS_DOWNLOAD_REPORT_PROCESS,
      GetBreakDownloadReportData
    ),
    takeLatest(
      BreakReportActionType.MONTHLY_BREAKS_REPORT_PROCESS,
      GetMonthlyBreakReportData
    ),
    takeLatest(
      BreakReportActionType.MONTHLY_BREAKS_DOWNLOAD_REPORT_PROCESS,
      GetMonthlyBreakDownloadReportData
    ),
    takeLatest(
      ProductivityReportActionType.PRODUCTIVITY_REPORT_PROCESS,
      GetProductivityReportData
    ),

    takeLatest(
      ProductivityReportActionType.PRODUCTIVITY_REPORT_DOWNLOAD_PROCESS,
      GetProductivityDownloadReportData
    ),

    takeLatest(
      ActivityReportActionType.ACTIVITY_REPORT_PROCESS,
      GetActivityReportData
    ),
    takeLatest(
      ActivityReportActionType.ACTIVITY_REPORT_DOWNLOAD_PROCESS,
      GetActivityDownloadReportData
    ),

    takeLatest(
      ApplicationReportActionType.APPLICATION_REPORT_GRAPH_PROCESS,
      GetApplicationReportGraphData
    ),
    takeLatest(
      ApplicationReportActionType.APPLICATION_REPORT_LIST_PROCESS,
      GetApplicationReportListData
    ),
    takeLatest(
      GroupinsightReportActionType.GROUP_INSIGHT_REPORT_PROCESS,
      GetGroupInsightReportData
    ),
    takeLatest(
      DynamicReportActionType.DOWNLOAD_DYNAMIC_REPORT,
      downloadDynamicReportSaga
    ),
    takeLatest(DownloadActionType.DOWNLOAD_STEALTH_APP, downloadStealthAppSaga),
    takeLatest(
      DownloadActionType.FETCH_APP_VERSION_DETAILS,
      fetchAppVersionDetailsSaga
    ),
    takeLatest(
      DownloadActionType.DOWNLOAD_STANDARD_APP,
      downloadStandardAppSaga
    ),
    takeLatest(DownloadActionType.DOWNLOAD_KEY_CONFIG, downloadKeyConfigSaga),

    takeLatest(
      ReportMonthlyInoutActionType.REPORT_MONTHLY_IN_OUT_PROCESS,
      ReportMonthlyInOut
    ),
    takeLatest(
      ReportMonthlyInoutActionType.REPORT_MONTHLY_IN_OUT_DOWNLOAD_PROCESS,
      DownloadReportMonthlyInOut
    ),

    takeLatest(
      ReportMonthlyAttendanceActionType.REPORT_MONTHLY_ATTENDANCE_PROCESS,
      ReportMonthlyAttendance
    ),

    takeLatest(
      ReportMonthlyAttendanceActionType.REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_PROCESS,
      ReportDownloadMonthlyAttendance
    ),

    takeLatest(
      AttendanceDetailedActionType.ATTENDANCE_DATEWISE_ATTENDANCE_LIST_PROCESS,
      GetAttendanceDatewiseAttendancelist
    ),
    takeLatest(
      MappingActionType.APPLICATION_POLICY_SEARCH_PROCESS,
      searchApplicationUrlList
    ),
    takeLatest(UserActionType.GET_USER_IDS_PROCESS, GetUserIdsList),

    // Timeline
    takeLatest(TimelineActionType.FETCH_TIMELINE_TABLE, fetchTimelineTableSaga),
    takeLatest(
      TimelineActionType.FETCH_HOURLY_ACTIVITY_PRODUCTIVITY,
      fetchHourlyActivityProductivitySaga
    ),
    takeLatest(TimelineActionType.FETCH_PUNCH_LOGS, fetchPunchLogsSaga),
    takeLatest(TimelineActionType.FETCH_POWER_LOGS, fetchPowerLogsSaga),
    takeLatest(
      TimelineActionType.FETCH_SESSION_APP_LOG,
      fetchSessionAppLogsSaga
    ),

    // Goals
    takeLatest(
      GoalsActionType.FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION,
      fetchGoalAchievementDistributionSaga
    ),
    takeLatest(
      GoalsActionType.FETCH_TEAMWISE_GOAL_COMPARISON,
      fetchTeamwiseGoalComparisonSaga
    ),
    takeLatest(
      GoalsActionType.FETCH_USERWISE_GOAL_ANALYTICS,
      fetchUserwiseGoalAnalyticsSaga
    ),
    takeLatest(
      GoalsActionType.FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD,
      fetchUserwiseGoalAnalyticsDownloadSaga
    ),

    // Livestream
    takeLatest(LivestreamActionType.START_LIVESTREAM, startLivestreamSaga),
    takeLatest(LivestreamActionType.START_SCREENSHOT, startScreenshotSaga),
    takeLatest(LivestreamActionType.FETCH_USER_DATA, fetchUserDataSaga),

    // Alerts
    takeLatest(AlertsActionType.CREATE_ALERT, createAlertSaga),
    takeLatest(AlertsActionType.FETCH_ALERTS, fetchAlertsSaga),
    takeLatest(AlertsActionType.UPDATE_ALERT, updateAlertSaga),
    takeLatest(AlertsActionType.DELETE_ALERT, deleteAlertSaga),

    // Roles
    takeLatest(RolesActionType.CREATE_ROLE, createRoleSaga),
    takeLatest(RolesActionType.FETCH_ROLES, fetchRolesSaga),
    takeLatest(RolesActionType.UPDATE_ROLE, updateRoleSaga),
    takeLatest(RolesActionType.DELETE_ROLE, deleteRoleSaga),

    // Notes
    takeLatest(NotesActionType.GET_NOTE, getNoteSaga),
    takeLatest(NotesActionType.CREATE_NOTE, createNoteSaga),
    takeLatest(NotesActionType.FETCH_NOTES, fetchNotesSaga),
    takeLatest(NotesActionType.UPDATE_NOTE, updateNoteSaga),
    takeLatest(NotesActionType.DELETE_NOTE, deleteNoteSaga),

    // Projects
    takeLatest(ProjectsActionType.GET_PROJECT, getProjectSaga),
    takeLatest(ProjectsActionType.CREATE_PROJECT, createProjectSaga),
    takeLatest(ProjectsActionType.FETCH_PROJECTS, fetchProjectsSaga),
    takeLatest(ProjectsActionType.UPDATE_PROJECT, updateProjectSaga),
    takeLatest(ProjectsActionType.DELETE_PROJECT, deleteProjectSaga),

    // Tasks
    takeLatest(TasksActionType.GET_TASK, getTaskSaga),
    takeLatest(TasksActionType.CREATE_TASK, createTaskSaga),
    takeLatest(TasksActionType.FETCH_TASKS, fetchTasksSaga),
    takeLatest(TasksActionType.FETCH_CHILD_TASKS, fetchChildTasksSaga),
    takeLatest(TasksActionType.UPDATE_TASK, updateTaskSaga),
    takeLatest(TasksActionType.DELETE_TASK, deleteTaskSaga),
    takeLatest(TasksActionType.GET_TASK_ATTACHMENT, getTaskAttachmentSaga),
    takeLatest(
      TasksActionType.CREATE_TASK_ATTACHMENT,
      createTaskAttachmentSaga
    ),
    takeLatest(
      TasksActionType.FETCH_TASK_ATTACHMENTS,
      fetchTaskAttachmentsSaga
    ),
    takeLatest(
      TasksActionType.UPDATE_TASK_ATTACHMENT,
      updateTaskAttachmentSaga
    ),
    takeLatest(
      TasksActionType.DELETE_TASK_ATTACHMENT,
      deleteTaskAttachmentSaga
    ),

    // Task Activities
    takeLatest(
      TaskActivitiesActionType.CREATE_TASK_ACTIVITY,
      createTaskActivitySaga
    ),
    takeLatest(
      TaskActivitiesActionType.FETCH_TASK_ACTIVITIES,
      fetchTaskActivitiesSaga
    ),
    takeLatest(
      TaskActivitiesActionType.UPDATE_TASK_ACTIVITY,
      updateTaskActivitySaga
    ),
    takeLatest(
      TaskActivitiesActionType.DELETE_TASK_ACTIVITY,
      deleteTaskActivitySaga
    ),

    // Integrations
    takeLatest(
      IntegrationsActionType.CREATE_INTEGRATION,
      createIntegrationSaga
    ),
    takeLatest(
      IntegrationsActionType.FETCH_INTEGRATIONS,
      fetchIntegrationsSaga
    ),
    takeLatest(
      IntegrationsActionType.UPDATE_INTEGRATION,
      updateIntegrationSaga
    ),
    takeLatest(
      IntegrationsActionType.DELETE_INTEGRATION,
      deleteIntegrationSaga
    ),

    // Billing
    takeLatest(BillingActionType.FETCH_SUBSCRIPTIONS, fetchSubscriptionsSaga),
    takeLatest(BillingActionType.FETCH_INVOICES, fetchInvoicesSaga),
    takeLatest(
      BillingActionType.SEND_INVOICE_VIA_EMAIL,
      sendInvoiceViaEmailSaga
    ),
    takeLatest(
      BillingActionType.INITIATE_SUBSCRIPTION,
      initiateSubscriptionSaga
    ),
    takeLatest(BillingActionType.UPDATE_SUBSCRIPTION, updateSubscriptionSaga),
    takeLatest(BillingActionType.FETCH_PLAN_GROUP, fetchPlanGroupSaga),

    takeLatest(
      Wellness360detailsActionType.WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_PROCESS,
      GetWellness360DownloadEmployeeData
    ),
    takeLatest(
      AttendanceDetailedActionType.ATTENDANCE_EMP_LIST_DOWNLOAD_PROCESS,
      GetDownloadAttendanceEmployeeList
    ),

    takeLatest(
      UserActionType.USER_BULK_IMPORT_VALIDATE_PROCESS,
      UserBulkImportValidate
    ),
    takeLatest(UserActionType.USER_BULK_IMPORT_PROCESS, UserBulkImport),
    takeLatest(
      UserActionType.USER_BULK_IMPORT_GET_JOB_STATUS_PROCESS,
      UserBulkImportGetJobStatus
    ),
    takeLatest(
      UserActionType.USER_BULK_IMPORT_LOGS_PROCESS,
      UserBulkImportlogs
    ),
    takeLatest(UserActionType.FETCH_ME_INFO, fetchMeInfoSaga),
    takeLatest(UserActionType.USER_NEW_LIST_PROCESS, userNewList),
    takeLatest(
      RoleActionType.USER_ROLE_SEARCH_LIST_PROCESS,
      roleAssignmentsSearch
    ),
    takeLatest(UserActionType.USER_SEARCH_LIST_PROCESS, userSearchList),
    takeLatest(
      ScreenshotActionType.FETCH_SCREENSHOT_LIST_USER_ID_PROCESS,
      fetchSecreenshotListByUserSaga
    ),
    takeLatest(
      ScreenshotActionType.DOWNLOAD_SCREENSHOT,
      downloadScreenshotSaga
    ),
    takeLatest(
      ScreenshotActionType.DOWNLOAD_SCREENSHOTS_ZIP,
      downloadScreenshotsZipSaga
    ),
    takeLatest(
      ScreenshotActionType.GET_FLAGGED_SCREENSHOT,
      getFlaggedScreenshotSaga
    ),
    takeLatest(ScreenshotActionType.FLAG_SCREENSHOT, flagScreenshotSaga),
    takeLatest(ScreenshotActionType.UNFLAG_SCREENSHOT, unflagScreenshotSaga),

    takeLatest(
      TenantSettingsActionType.FETCH_TENANT_SETTINGS,
      fetchTenantSettingsSaga
    ),
    takeLatest(TenantSettingsActionType.FETCH_USER_SESSION, fetchUserSession),

    takeLatest(
      TenantSettingsActionType.UPDATE_TENANT_SETTINGS,
      updateTenantSettingsSaga
    ),
    takeLatest(AlertsActionType.FETCH_ALERTS_LOGS, fetchAlertLogsSaga),
    takeLatest(AlertsActionType.DOWNLOAD_ALERTS_LOGS, downloadAlertLogsSaga),
    takeLatest(
      MappingActionType.APPLICATION_MAPPING_DOWNLOAD_PROCESS,
      GetApplicationUrlDownloadList
    ),
    takeLatest(
      ApplicationReportActionType.APPLICATION_REPORT_EXPORT_LIST_PROCESS,
      getApplicationReportExportListData
    ),
    takeLatest(UserActionType.EXPORT_USER_LIST_PROCESS, exportUserList),

    takeLatest(FieldActionType.FETCH_DAILY_PINGS, fetchDailyPingsSaga),

    takeLatest(LeaveTypeActionType.LEAVE_TYPE_LIST_PROCESS, GetLeaveType),
    takeLatest(LeaveTypeActionType.LEAVE_TYPE_CREATE_PROCESS, CreateLeaveType),
    takeLatest(LeaveTypeActionType.LEAVE_TYPE_EDIT_PROCESS, EditLeaveType),

    takeLatest(LeaveAction.LEAVE_LIST_PROCESS, GetLeave),
    takeLatest(LeaveAction.LEAVE_CREATE_PROCESS, CreateLeave),
    takeLatest(LeaveAction.LEAVE_EDIT_PROCESS, EditLeave),
    takeLatest(
      UserDeviceActionType.PC_USER_DEVICE_INFO_PROCESS,
      getPcUserDeviceInfo
    ),
    takeLatest(
      UserDeviceActionType.MOBILE_USER_DEVICE_INFO_PROCESS,
      getMobileUserDeviceInfo
    ),
    takeLatest(
      UserDeviceActionType.USER_DEVICE_COUNT_PROCESS,
      getUserDeviceCount
    ),

    takeLatest(UserDeviceActionType.MOBILE_PLATFORM_PROCESS, getMobilePlatform),
    takeLatest(UserDeviceActionType.PC_PLATFORM_PROCESS, getPCPlatform),
    takeLatest(UserDeviceActionType.PC_SYSTEM_TYPE_PROCESS, getPCSystemType),
    takeLatest(
      UserDeviceActionType.PC_MYZEN_VERSION_PROCESS,
      getPCMyzenVersion
    ),
    takeLatest(
      UserDeviceActionType.PC_USER_DEVICE_INFO_DOWNLOAD_PROCESS,
      getPcUserDeviceInfoDownload
    ),
    takeLatest(
      UserDeviceActionType.MOBILE_USER_DEVICE_INFO_DOWNLOAD_PROCESS,
      getMobileUserDeviceInfoDownload
    ),
    takeLatest(
      ProjectsReportActionType.FETCH_PROJECTS_REPORT,
      fetchProjectsReportSaga
    ),
    takeLatest(
      ProjectsReportActionType.DOWNLOAD_PROJECTS_REPORT,
      downloadProjectsReportSaga
    ),
    takeLatest(LogsReportActionType.FETCH_LOGS_REPORT, fetchLogsReportSaga),
    takeLatest(
      LogsReportActionType.DOWNLOAD_LOGS_REPORT,
      downloadLogsReportSaga
    ),

    // Manualtime

    takeLatest(manualTimeActionType.FETCH_MANUALTIME, fetchManualTimeSaga),
    takeLatest(manualTimeActionType.CREATE_MANUALTIME, createManualTimeSaga),
    takeLatest(manualTimeActionType.UPDATE_MANUALTIME, updateManualTimeSaga),
    takeLatest(manualTimeActionType.DELETE_MANUALTIME, deleteManualTimeSaga),

    // cross day attendnace report
    takeLatest(
      CrossDayAttendanceReportActionType.FETCH_CROSS_DAY_ATTENDANCE_REPORT,
      fetchCrossDayAttendanceReportSaga
    ),
    takeLatest(
      CrossDayAttendanceReportActionType.DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT,
      downloadCrossDayAttendanceReportSaga
    ),
    //User Score Card
    takeLatest(
      UserScorecardActionType.COMPARATIVE_ANALYSIS_USER_PERFORMANCE_PROCESS,
      getComparativeAnalysisUserPerformanceInfo
    ),
    takeLatest(
      UserScorecardActionType.COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_PROCESS,
      getComparativeAttendanceUserPerformanceInfo
    ),
    takeLatest(
      UserScorecardActionType.COMPARATIVE_GOAL_USER_PERFORMANCE_PROCESS,
      getComparativeGoalUserPerformanceInfo
    ),
    takeLatest(
      UserScorecardActionType.COMPARATIVE_APPLICATION_USER_PERFORMANCE_PROCESS,
      getComparativeApplicationUserPerformanceInfo
    ),

    // Timesheet

    takeLatest(TimesheetActionType.FETCH_TIMESHEET, fetchTimesheetSaga),
    takeLatest(TimesheetActionType.CREATE_TIMESHEET, createTimesheetSaga),
    takeLatest(TimesheetActionType.UPDATE_TIMESHEET, updateTimesheetSaga),
    takeLatest(TimesheetActionType.DELETE_TIMESHEET, deleteTimesheetSaga),
    takeLatest(UserActionType.BULK_USER_TEAM_UPDATE, bulkTeamUpdateSaga),
    takeLatest(
      TimesheetActionType.BULK_SUBMIT_TIMESHEET,
      bulkSubmitTimesheetSaga
    ),
    takeLatest(
      TimesheetActionType.EDIT_TIMESHEET_DETAIL,
      editTimesheetDetailSaga
    ),
  ]);
}
